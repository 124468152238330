<template>
    <div class="mod-config">
        <el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
            <div class="title">监理文件类型设置</div>
            <div class="line"></div>
            <el-row style="margin-bottom: 5px;">
                <el-col :span="pageSpan">
                    <el-button v-preventReClick class="btn" type="primary" size="small"
                        @click="addOrUpdateHandle()">新增类型</el-button>
                </el-col>
            </el-row>
        </el-card>
        <div :style="siteContentViewHeight()">
            <el-card class="card" body-style="height: 100%; padding: 1;" style="margin-top:-2px">
                <div class="tree-container">
                    <el-tree :data="dataList" node-key="recId" default-expand-all :expand-on-click-node="false"
                        :props="defaultProps" style="background-color: white;">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span>
                                <el-button v-preventReClick type="text" size="mini"
                                    v-if="data.oaDirId == '' || data.oaDirId == null"
                                    @click="addOrUpdateHandle(data.recId)">修改</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    v-if="data.oaDirId == '' || data.oaDirId == null"
                                    @click="deleteHandle(data.recId)">删除</el-button>
                                <el-button v-preventReClick type="text" size="mini" v-if="node.level != 3"
                                    @click="addChildFileKindHandle(data.recId)">添加子类型</el-button>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </el-card>
        </div>
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
        <child-file-setting-add v-if="childFileSettingAddVisible" ref="childFileSettingAdd"
            @refreshDataList="getDataList()"></child-file-setting-add>
    </div>
</template>
<script>
import AddOrUpdate from './file-setting-add-or-update.vue'
import ChildFileSettingAdd from './child-file-setting-add.vue'
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            showAdd: false,
            showFileKindSet: false,
            showFileDownload: false,
            showCheck: false,
            showEdit: false,
            showArchive: false,
            showDelete: false,
            pageSpan: 12,
            tabIndex: '',
            addOrUpdateVisible: false,
            childFileSettingAddVisible: false,
            recId: '',
            examineTime: [],
            dataList: [],
            tableSelVal: [],
            //审查文件类型
            documentTypeList: [],
            //当前页码
            pageIndex: 1,
            //当前记录数
            pageSize: 10,
            //总页数
            totalPage: 0,
            defaultProps: {
                children: 'childList',
                label: 'kindName'
            }
        };
    },
    components: {
        AddOrUpdate,
        ChildFileSettingAdd
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        //获取数据
        getDataList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/filekindconfig/getTreeList",
                method: "get",
                params: {
                    workNo: $common.getItem("workNo"),
                    kindType: "02",
                    limit: this.pageSize,
                    page: this.pageIndex
                },
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataList = data.body;
                    this.totalPage = data.body.totalCount;
                }
            });
        },
        //删除
        deleteHandle(id) {
            this.recId = id ? id : 0;
            this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$store.state.httpUrl + `/business/filekindconfig/delete/${this.recId}`,
                    method: "POST"
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList()
                            }
                        })
                    }
                });
            })
                .catch(() => { })
        },
        handleSelectionChange(val) {
            this.tableSelVal = val;
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id, "02")
            })
        },
        addChildFileKindHandle(id) {
            this.childFileSettingAddVisible = true
            this.$nextTick(() => {
                this.$refs.childFileSettingAdd.init(id, "02")
            })
        },
        selGetDataList() {
            this.pageIndex = 1;
            this.getDataList();
        },
        siteContentViewHeight() {
            var height = this.$store.state.common.documentClientHeight - 160;
            return { height: height + 'px' }
        },
    }
};
</script>
<style scoped="scoped">
.selItemText {
    font-size: 14px;
    color: #1c1c1c;
    display: inline-block;
    width: 80px;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.tree-container {
    background-color: white;
}

/* 设置树形组件节点的定位和左内边距 */
.tree-container /deep/ .el-tree-node {
    position: relative;
    padding-left: 13px;
}

/* 设置树形组件孩子节点左内边距 */
.tree-container /deep/ .el-tree .el-tree-node__children {
    padding-left: 13px;
}

.card {
    margin-top: 20px;
    height: 100%;
    overflow-y: auto
        /* 开启滚动显示溢出内容 */
}
</style>
